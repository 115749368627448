.homepage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-fluid {
  height: 80vh;
}

.home-row {
  width: 100%;
}

.h1-over-the-line {
  background-color: var(--main-bg-color);
  width: 100%;
  text-align: center;
  padding: 10px;
  color: var(--main-white);
  padding-bottom: 2px;
}

.h1-over-the-line:hover {
  transform: scale(1.1);
  transition-duration: 0.5s;
}

.h1-link {
  width: 100%;
  text-decoration: none;
  cursor: pointer;
}

.h1-link:hover {
  width: 100%;
  text-decoration: none;
}

.text h1 {
  color: var(--main-white);
  padding-top: 12px;
}

.link-brush {
  width: 100%;
  height: 100px;
}

.link-brush {
  width: 100%;
  height: 100px;
  margin-top: 200px;
  text-decoration: none;
  color: red;
}

@media (min-aspect-ratio: 16/9) {
  #videoBG {
    width: 100%;
    height: 100vh;
  }
}

@media (max-width: 768px) {
  #videoBG {
    width: auto;
    height: auto;
  }

  .homepage {
    margin-bottom: 100px;
  }
}

@media only screen and (device-width: 1920px) and (max-device-height: 1080px) {
  .section-line {
    margin-bottom: 60px;
  }

  #videoBG {
    width: 100%;
    height: 100vh;
  }
}

@media only screen and (min-width: 1300px) {
  #videoBG {
    width: 100%;
    height: 100vh;
  }
}