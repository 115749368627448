.back-to-top {
    margin-bottom: 10px;
    padding: 10px 30px;
    border: none;
    background-color: var(--main-bg-color);
    color: var(--main-white);
    padding-bottom: 1px;
    text-decoration: none !important;
    cursor: pointer;
    outline: none;
    z-index: 10;
    padding-bottom: 3px;
}

.back-to-top:hover {
    background-color: var(--main-white);
    color: var(--main-black);
    border: 1px solid var(--main-bg-color);
}

.back-to-top:focus {
    outline: none;
}

@media(max-width: 768px) {}

@media(max-width: 375px) {}