.footer {
  width: 100% !important;
  background-color: var(--main-white);
  display: flex;
  justify-content: center;
  flex-grow: 1;
  height: 140px;
}



.footer-links ul {
  margin: 0px;
  padding: 0px;
}

.footer-links li {
  list-style-type: none;
}

.footer-links li a:hover {
  color: var(--main-bg-color);
  text-decoration: none !important;
}

.div-links {
  display: flex;
  flex-direction: column;
}

.div-links a {
  margin: 5px;
  color: var(--main-black);
  text-decoration: none !important;
}

.dropdown-menu {
  border: none !important;
}

.dropdown-item:hover {
  background-color: transparent !important;
}

.Select-option.is-hover {
  background-color: rgba(1, 198, 136, 0.15) !important;
}

#dropdown-basic {
  background-color: transparent !important;
  border: none !important;
  color: var(--main-black) !important;
  outline: none !important;
  margin-top: -1px;
}

#dropdown-basic:focus {
  box-shadow: none !important;
}

.footer-main {
  flex-grow: 1;
}


.social-list {
  padding: 0px;
}

.social li {
  padding-left: 20px;
  list-style-type: none;
}


.survey-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.survey-btn a {
  padding: 8px 20px;
  background-color: var(--main-bg-white);
  color: var(--main-bg-color);
  padding-bottom: 2px;
  border: 2px solid var(--main-bg-color);
  border-radius: 20px;
  text-decoration: none !important;
  font-weight: bold;
}

.survey-btn a:hover {
  color: var(--main-bg-color);
  border: 2px solid var(--main-bg-white);
}

.copyFooter a {
  color: var(--main-black);
  text-decoration: none !important;

}

.copyFooter a:hover {
  color: var(--main-bg-color);
}



@media only screen and (max-width: 768px) {
  .footer {
    margin-top: 5em;
  }

  .survey-btn {
    margin-top: 20px;
  }

  .copyFooter {
    margin-top: 20px;
  }

  .footer {
    height: 250px;
  }


}