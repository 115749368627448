:root {
  --main-bg-color: #e8332c;
  --main-white: #fff;
  --main-black: #000;
  --main-blue: #48b2d7;
  --main-yellow: #ffff00;
  --main-orange: #f17517;

}



.App {
  text-align: center;
}


/* multiple-artist page */

.element {
  background-image: url("./assets/bg-over-the-line.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}



/* bubbles */
.bubbles-link-artists {
  color: var(--main-black);
  font-size: 1.1rem;
  cursor: pointer;
  padding: 20px;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
}

.bubbles-link-artists:hover {
  text-decoration: none;
  transition: ease-in-out 0.2s;
  transform: translate(0%, 0%);
  color: var(--main-black);

}

.bubble-border {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 180px;
  transform: translate(-50%, -50%);
}




.yellow,
.blue,
.orange {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: shake 10s;
  animation-iteration-count: infinite;
  transform: scale(1.1);
}

.yellow:hover,
.blue:hover,
.orange:hover {
  animation: none;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, 1px) rotate(-1deg);
  }
}

.blue {
  background-color: var(--main-blue);
}

.orange {
  background-color: var(--main-orange);
}

.yellow {
  background-color: var(--main-yellow);
}

.section-line {
  width: 100%;
  height: 0.5vh;
  background-color: var(--main-bg-color);
  margin-top: -20px;
  margin-bottom: 10px;
}

.container-fluid {
  padding-top: 5em;
  padding-left: 5em !important;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

@media only screen and (max-width: 768px) {


  .yellow,
  .blue,
  .orange {
    width: 90px;
    height: 90px;

  }

  .section-line {
    margin-bottom: 2px;
  }

  html,
  body {
    width: 100% !important;
  }

  .container-fluid {
    padding-top: 0em;
    padding-left: 10px !important;
  }


}

@media only screen and (device-width: 1920px) and (device-height: 1080px) {
  .section-line {
    margin-bottom: 60px;
  }
}