.bubble-border-artists {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 140px;
  transform: translate(-50%, -50%);
}

.artist_bubbles {
  position: relative;
  flex-wrap: wrap;
  justify-content: center;

}

@media only screen and (max-width: 768px) {

  .writing-container {
    padding-top: 7em !important;

    height: auto;
  }

  .bubble-border-artists {

    width: 110px !important;
    height: 110px !important;
  }

}