.container-fluid {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#blue {
  width: 150px;
  height: 150px;
  border: none;
}

#orange {
  width: 150px;
  height: 150px;
  border: none;
  margin-top: 10em;
}

#yellow {
  width: 150px;
  height: 150px;
  border: none;
}

.categories {
  flex-direction: column;
  justify-content: center;
}

.categories h5 {
  margin-top: 5em;
  padding-bottom: 2em;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  #blue {
    padding: 0;
    margin-top: 0px;
  }

  #orange {
    margin-top: 0em;
  }

  .categories {
    padding-top: 5em;
  }

  .categories h5 {
    margin-top: 6em;
    padding-bottom: 1em;
  }
}

@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .categories {
    flex-direction: column;
    justify-content: center;
    padding-top: 7em;
  }

  .categories h5 {
    margin-top: 7em;
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) {
  .categories {
    flex-direction: column;
    justify-content: center;
    padding-top: 6em;
  }

  .categories h5 {
    margin-top: 9em;
  }
}