@import url(https://fonts.googleapis.com/css2?family=Baloo+Tamma+2:wght@400;600;800&display=swap);
.navbar-bg-color {
  background-color: var(--main-bg-color);
  transition: ease-in-out 0.5s;
  z-index: 1;
}


.main-white {
  color: var(--main-bg-color);
}

#logo {
  margin-right: 0rem !important;
}

.heart path {
  fill: var(--main-bg-color);
}

.heartScroll path {
  fill: var(--main-white);
}

.donate-link-red {
  color: var(--main-black);
  padding: 2px;
  font-size: 23px;
  padding: 0px !important;
}

.donate-link-white {
  color: var(--main-white);
  padding: 2px;
  font-size: 23px;
  padding: 0px !important;

}


.donate-link-red a {
  color: var(--main-bg-color);
  padding: 5px 10px;
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 2px;
}

.donate-link-red a:hover {
  color: var(--main-bg-color);
  transform: scale(1.1);
  text-decoration: none;

}


.donate-link-white a {
  color: var(--main-white);
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 2px;
  padding: 5px 10px;
}

.navbar-expand {
  flex-flow: row wrap;
  justify-content: flex-start;
}

.text-scroll h1 {
  color: var(--main-white) !important;
  margin-top: 15px;
}

.navbar {
  padding: 0 !important;


  border: 0 !important;

}

.text {
  opacity: 0;
}

.donate-link-white a:hover {
  color: var(--main-white);
  transform: scale(1.1);
  text-decoration: none;
}





@media only screen and (max-width: 768px) {
  .text-scroll h1 {
    font-size: 24px;
    width: 100%;
  }

  .logo {
    width: 150px;
    height: 60px;
  }

}

@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .text-scroll h1 {
    font-size: 24px;
    width: 100%;
  }

  .logo {
    width: 200px;
    height: 60px;
  }
}
.homepage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-fluid {
  height: 80vh;
}

.home-row {
  width: 100%;
}

.h1-over-the-line {
  background-color: var(--main-bg-color);
  width: 100%;
  text-align: center;
  padding: 10px;
  color: var(--main-white);
  padding-bottom: 2px;
}

.h1-over-the-line:hover {
  transform: scale(1.1);
  transition-duration: 0.5s;
}

.h1-link {
  width: 100%;
  text-decoration: none;
  cursor: pointer;
}

.h1-link:hover {
  width: 100%;
  text-decoration: none;
}

.text h1 {
  color: var(--main-white);
  padding-top: 12px;
}

.link-brush {
  width: 100%;
  height: 100px;
}

.link-brush {
  width: 100%;
  height: 100px;
  margin-top: 200px;
  text-decoration: none;
  color: red;
}

@media (min-aspect-ratio: 16/9) {
  #videoBG {
    width: 100%;
    height: 100vh;
  }
}

@media (max-width: 768px) {
  #videoBG {
    width: auto;
    height: auto;
  }

  .homepage {
    margin-bottom: 100px;
  }
}

@media only screen and (device-width: 1920px) and (max-device-height: 1080px) {
  .section-line {
    margin-bottom: 60px;
  }

  #videoBG {
    width: 100%;
    height: 100vh;
  }
}

@media only screen and (min-width: 1300px) {
  #videoBG {
    width: 100%;
    height: 100vh;
  }
}
.container-fluid {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#blue {
  width: 150px;
  height: 150px;
  border: none;
}

#orange {
  width: 150px;
  height: 150px;
  border: none;
  margin-top: 10em;
}

#yellow {
  width: 150px;
  height: 150px;
  border: none;
}

.categories {
  flex-direction: column;
  justify-content: center;
}

.categories h5 {
  margin-top: 5em;
  padding-bottom: 2em;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  #blue {
    padding: 0;
    margin-top: 0px;
  }

  #orange {
    margin-top: 0em;
  }

  .categories {
    padding-top: 5em;
  }

  .categories h5 {
    margin-top: 6em;
    padding-bottom: 1em;
  }
}

@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .categories {
    flex-direction: column;
    justify-content: center;
    padding-top: 7em;
  }

  .categories h5 {
    margin-top: 7em;
  }
}

@media only screen and (device-width: 375px) and (device-height: 667px) {
  .categories {
    flex-direction: column;
    justify-content: center;
    padding-top: 6em;
  }

  .categories h5 {
    margin-top: 9em;
  }
}
.bubble-border-artists {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 140px;
  transform: translate(-50%, -50%);
}

.artist_bubbles {
  position: relative;
  flex-wrap: wrap;
  justify-content: center;

}

@media only screen and (max-width: 768px) {

  .writing-container {
    padding-top: 7em !important;

    height: auto;
  }

  .bubble-border-artists {

    width: 110px !important;
    height: 110px !important;
  }

}
.footer {
  width: 100% !important;
  background-color: var(--main-white);
  display: flex;
  justify-content: center;
  flex-grow: 1;
  height: 140px;
}



.footer-links ul {
  margin: 0px;
  padding: 0px;
}

.footer-links li {
  list-style-type: none;
}

.footer-links li a:hover {
  color: var(--main-bg-color);
  text-decoration: none !important;
}

.div-links {
  display: flex;
  flex-direction: column;
}

.div-links a {
  margin: 5px;
  color: var(--main-black);
  text-decoration: none !important;
}

.dropdown-menu {
  border: none !important;
}

.dropdown-item:hover {
  background-color: transparent !important;
}

.Select-option.is-hover {
  background-color: rgba(1, 198, 136, 0.15) !important;
}

#dropdown-basic {
  background-color: transparent !important;
  border: none !important;
  color: var(--main-black) !important;
  outline: none !important;
  margin-top: -1px;
}

#dropdown-basic:focus {
  box-shadow: none !important;
}

.footer-main {
  flex-grow: 1;
}


.social-list {
  padding: 0px;
}

.social li {
  padding-left: 20px;
  list-style-type: none;
}


.survey-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.survey-btn a {
  padding: 8px 20px;
  background-color: var(--main-bg-white);
  color: var(--main-bg-color);
  padding-bottom: 2px;
  border: 2px solid var(--main-bg-color);
  border-radius: 20px;
  text-decoration: none !important;
  font-weight: bold;
}

.survey-btn a:hover {
  color: var(--main-bg-color);
  border: 2px solid var(--main-bg-white);
}

.copyFooter a {
  color: var(--main-black);
  text-decoration: none !important;

}

.copyFooter a:hover {
  color: var(--main-bg-color);
}



@media only screen and (max-width: 768px) {
  .footer {
    margin-top: 5em;
  }

  .survey-btn {
    margin-top: 20px;
  }

  .copyFooter {
    margin-top: 20px;
  }

  .footer {
    height: 250px;
  }


}
.back-to-top {
    margin-bottom: 10px;
    padding: 10px 30px;
    border: none;
    background-color: var(--main-bg-color);
    color: var(--main-white);
    padding-bottom: 1px;
    text-decoration: none !important;
    cursor: pointer;
    outline: none;
    z-index: 10;
    padding-bottom: 3px;
}

.back-to-top:hover {
    background-color: var(--main-white);
    color: var(--main-black);
    border: 1px solid var(--main-bg-color);
}

.back-to-top:focus {
    outline: none;
}

@media(max-width: 768px) {}

@media(max-width: 375px) {}
.product-writing {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  border: 1px solid var(--main-bg-color);
  padding: 20px;
  background-color: var(--main-white);
  text-align: left;
}

.product-container-info {
  height: 400px;
  width: 100%;
  border: 1px solid var(--main-bg-color);
  padding: 20px;
  background-color: var(--main-white);
  text-align: left;
  overflow: auto;
}

.container-product {
  padding-left: 0 !important;
  padding-right: 0 !important;
  flex-direction: column;

}

.product-container {
  padding-left: 10% !important;
  padding-right: 10% !important;
}


.back-btn {
  padding-bottom: 10px;
  padding-top: 10px;
}


@media only screen and (max-width: 768px) {
  .product-container-info {
    margin-top: 20px;
  }

  .back-btn {
    padding-top: 10px;
  }

  .product-container {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }

  .container-product {
    display: block;
    padding-top: 10em;
  }

  .video-writing-div {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

}

@media only screen and (device-width: 1024px) and (device-height: 1366px) {
  .product-container {
    padding-left: 0% !important;
    padding-right: 0% !important;
    height: 80vh;
    align-items: center;
  }

  .footer {
    height: 200px;
  }
}

@media only screen and (device-width: 1920px) and (device-height: 1080px) {
  .product-container {
    padding-left: 0% !important;
    padding-right: 0% !important;
    height: 70vh;
    align-items: center;
  }

  .footer {
    height: 200px;
  }
}
.bubble-border-products {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 180px;
    transform: translate(-50%, -50%);
}

#products-bubbles {
    width: 150px;
    height: 150px;
    margin-top: 100px
}

.products_bubbles {
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
}


@media only screen and (max-width: 768px) {
    .products_bubbles {
        flex-direction: column;
        padding-top: 5em;
    }

    .bubble-border-products {

        width: 160px;

    }

    #products-bubbles {
        width: 130px;
        height: 130px;
        margin-top: 10px
    }

}
:root {
  --main-bg-color: #e8332c;
  --main-white: #fff;
  --main-black: #000;
  --main-blue: #48b2d7;
  --main-yellow: #ffff00;
  --main-orange: #f17517;

}



.App {
  text-align: center;
}


/* multiple-artist page */

.element {
  background-image: url(/static/media/bg-over-the-line.58e816ce.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}



/* bubbles */
.bubbles-link-artists {
  color: #000;
  color: var(--main-black);
  font-size: 1.1rem;
  cursor: pointer;
  padding: 20px;
  text-align: center;
  font-weight: 600;
  text-decoration: none;
}

.bubbles-link-artists:hover {
  text-decoration: none;
  transition: ease-in-out 0.2s;
  transform: translate(0%, 0%);
  color: #000;
  color: var(--main-black);

}

.bubble-border {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 180px;
  transform: translate(-50%, -50%);
}




.yellow,
.blue,
.orange {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: shake 10s;
          animation: shake 10s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  transform: scale(1.1);
}

.yellow:hover,
.blue:hover,
.orange:hover {
  -webkit-animation: none;
          animation: none;
}

@-webkit-keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, 1px) rotate(-1deg);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, 1px) rotate(-1deg);
  }
}

.blue {
  background-color: #48b2d7;
  background-color: var(--main-blue);
}

.orange {
  background-color: #f17517;
  background-color: var(--main-orange);
}

.yellow {
  background-color: #ffff00;
  background-color: var(--main-yellow);
}

.section-line {
  width: 100%;
  height: 0.5vh;
  background-color: #e8332c;
  background-color: var(--main-bg-color);
  margin-top: -20px;
  margin-bottom: 10px;
}

.container-fluid {
  padding-top: 5em;
  padding-left: 5em !important;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

@media only screen and (max-width: 768px) {


  .yellow,
  .blue,
  .orange {
    width: 90px;
    height: 90px;

  }

  .section-line {
    margin-bottom: 2px;
  }

  html,
  body {
    width: 100% !important;
  }

  .container-fluid {
    padding-top: 0em;
    padding-left: 10px !important;
  }


}

@media only screen and (device-width: 1920px) and (device-height: 1080px) {
  .section-line {
    margin-bottom: 60px;
  }
}
body {
    font-family: "Baloo Tamma 2",
        cursive;
}

* {
    margin: 0;
}
