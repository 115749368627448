.product-writing {
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  border: 1px solid var(--main-bg-color);
  padding: 20px;
  background-color: var(--main-white);
  text-align: left;
}

.product-container-info {
  height: 400px;
  width: 100%;
  border: 1px solid var(--main-bg-color);
  padding: 20px;
  background-color: var(--main-white);
  text-align: left;
  overflow: auto;
}

.container-product {
  padding-left: 0 !important;
  padding-right: 0 !important;
  flex-direction: column;

}

.product-container {
  padding-left: 10% !important;
  padding-right: 10% !important;
}


.back-btn {
  padding-bottom: 10px;
  padding-top: 10px;
}


@media only screen and (max-width: 768px) {
  .product-container-info {
    margin-top: 20px;
  }

  .back-btn {
    padding-top: 10px;
  }

  .product-container {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }

  .container-product {
    display: block;
    padding-top: 10em;
  }

  .video-writing-div {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

}

@media only screen and (device-width: 1024px) and (device-height: 1366px) {
  .product-container {
    padding-left: 0% !important;
    padding-right: 0% !important;
    height: 80vh;
    align-items: center;
  }

  .footer {
    height: 200px;
  }
}

@media only screen and (device-width: 1920px) and (device-height: 1080px) {
  .product-container {
    padding-left: 0% !important;
    padding-right: 0% !important;
    height: 70vh;
    align-items: center;
  }

  .footer {
    height: 200px;
  }
}