.bubble-border-products {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 180px;
    transform: translate(-50%, -50%);
}

#products-bubbles {
    width: 150px;
    height: 150px;
    margin-top: 100px
}

.products_bubbles {
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
}


@media only screen and (max-width: 768px) {
    .products_bubbles {
        flex-direction: column;
        padding-top: 5em;
    }

    .bubble-border-products {

        width: 160px;

    }

    #products-bubbles {
        width: 130px;
        height: 130px;
        margin-top: 10px
    }

}