.navbar-bg-color {
  background-color: var(--main-bg-color);
  transition: ease-in-out 0.5s;
  z-index: 1;
}


.main-white {
  color: var(--main-bg-color);
}

#logo {
  margin-right: 0rem !important;
}

.heart path {
  fill: var(--main-bg-color);
}

.heartScroll path {
  fill: var(--main-white);
}

.donate-link-red {
  color: var(--main-black);
  padding: 2px;
  font-size: 23px;
  padding: 0px !important;
}

.donate-link-white {
  color: var(--main-white);
  padding: 2px;
  font-size: 23px;
  padding: 0px !important;

}


.donate-link-red a {
  color: var(--main-bg-color);
  padding: 5px 10px;
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 2px;
}

.donate-link-red a:hover {
  color: var(--main-bg-color);
  transform: scale(1.1);
  text-decoration: none;

}


.donate-link-white a {
  color: var(--main-white);
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 2px;
  padding: 5px 10px;
}

.navbar-expand {
  flex-flow: row wrap;
  justify-content: flex-start;
}

.text-scroll h1 {
  color: var(--main-white) !important;
  margin-top: 15px;
}

.navbar {
  padding: 0 !important;


  border: 0 !important;

}

.text {
  opacity: 0;
}

.donate-link-white a:hover {
  color: var(--main-white);
  transform: scale(1.1);
  text-decoration: none;
}





@media only screen and (max-width: 768px) {
  .text-scroll h1 {
    font-size: 24px;
    width: 100%;
  }

  .logo {
    width: 150px;
    height: 60px;
  }

}

@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .text-scroll h1 {
    font-size: 24px;
    width: 100%;
  }

  .logo {
    width: 200px;
    height: 60px;
  }
}